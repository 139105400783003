<template>
  <div class="settingList">
    <span class="tip">更换手机号，账号将绑定新手机号，当前手机号{{userInfo.phone}}。</span>

    <van-cell-group style="margin-top: 6px">
      <van-field v-model="loginData.mobile" label="手机号" label-width="60px"
                 @input="inputChange" maxlength="11" type="number" center clearable
                 placeholder="请输入新手机号"/>

      <van-field v-model="loginData.smsCode" label="验证码" label-width="60px"
                 @input="inputChange" maxlength="6" type="number" center clearable
                 placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small" native-type="button" @click="sendCode" :disabled="isSendDisabled" type="primary">
            {{ sendCodeButtonText }}
          </van-button>
        </template>
      </van-field>
    </van-cell-group>

    <van-button round block type="info" :class="{applyBtn:true ,active:isActive}" @click="changeMobile"
                native-type="submit">{{ '更换手机号' }}
    </van-button>
  </div>

</template>

<script>
  export default {
    name: "changemobile",
    data() {
      return {
        userInfo: {},
        loginData: {
          "mobile": "",
          "smsCode": "",
          "type": 1
        },
        isSendDisabled: false,
        sendCodeButtonText: '获取验证码',
        timeNumber: 60,
        isActive: false,
        isSubmit: false,
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.userInfo)
    },
    methods: {
      sendCode() {
        if (this.loginData.mobile.length == 0) {
          return false;
        }
        this.timer = setInterval(this.doLoop, 1000);
        this.isSendDisabled = true;
        this.$api.user.sendsms({
          "mobile": this.loginData.mobile,
          "type": 1
        }, res => {
          if (res.code == 200) {
            this.isSendDisabled = true;
          } else {
            clearInterval(this.timer);
            this.sendCodeButtonText = '获取验证码';
            this.isSendDisabled = false;
          }
        }, err =>{
          clearInterval(this.timer);
          this.sendCodeButtonText = '获取验证码';
          this.isSendDisabled = false;
        })
      },
      //倒计时开始
      doLoop() {
        this.timeNumber--;
        if (this.timeNumber > 0) {
          this.sendCodeButtonText = "   " + this.timeNumber + 'S';
        } else {
          clearInterval(this.timer); //清除js定时器
          this.isDisabled = false;
          this.sendCodeButtonText = '获取验证码';
          this.timeNumber = 60; //重置时间
        }
      },
      //输入框变化
      inputChange() {
        let mobileLength = this.loginData.mobile.length;
        let smsCodeLength = this.loginData.smsCode.length;
        if (mobileLength == 11 && smsCodeLength == 6) {
          this.isTap(true);
        } else {
          this.isTap(false);
        }
      },
      isTap(off) {
        if (off) {
          this.isSubmit = false;
          this.isActive = true;
        } else {
          this.isSubmit = true;
          this.isActive = false;
        }
      },
      changeMobile(){
        this.$toast.loading("保存中...")
        this.isSubmit = true

        this.$api.user.updateMobile(this.loginData, res => {
          this.$toast.clear()
          this.isSubmit = false
          if (res.code == 200) {
            this.$toast.success("手机号更换成功")
            this.userInfo.phone = this.loginData.mobile
            localStorage.setItem("userInfo", JSON.stringify(this.userInfo))
            this.$router.back()
          }else {
            this.$toast.fail(res.msg)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .settingList {
    width: 100%;
    overflow: hidden;
  }

  .tip {
    font-size: 24px;
    padding: 16px 16px 30px 30px;
    color: #686868;
  }

  .formItem {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;

    &.active {
      margin-top: 62px;
    }

    img {
      width: 32px;
      height: 36px;
      margin-right: 20px;
    }

    .phone {
      width: 28px;
      height: 40px;
    }

  }

  .van-cell {
    width: 100%;
  }

  .van-field {
    margin: 0 auto;
    height: 98px;
    color: #333333;
    font-size: 28px;
  }

  .van-field__control {
    text-indent: 30px;
  }

  .van-button {
    background: #fff;
    color: #3E639A;
    font-size: 28px;
    cursor: pointer;
    border: none;
  }

  .applyBtn {
    margin: 312px auto 0;
    width: 594px;
    border: none;
    height: 88px;
    background: #dcdcdc;
    color: #fff;
    cursor: pointer;
    font-size: 32px;
  }

  .applyBtn.active {
    background: #3E639A;
  }
</style>